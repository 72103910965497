import * as React from 'react';
import logo from '../../img/gamepass.svg';
import noGamepass from '../../img/no-gamepass.svg';

interface Props {
  isOnGamePass: boolean;
}

export default function Gamepass({ isOnGamePass }: Props): JSX.Element {
  return (
    <>
      <img
        className='game-pass-icon'
        src={isOnGamePass ? logo : noGamepass}
        alt='Game Pass logo'
        width='100px'
        height='auto'
      />
    </>
  );
}
