// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import { useTheme } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import fetch from 'cross-fetch';
import React from 'react';
import { GameRawg } from '../entities/GameRawg';

interface Props {
  onSelected: React.EventHandler<React.ChangeEvent<{}>>;
  handleOpenClose: Function;
}

export default function Search({ onSelected, handleOpenClose }: Props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<GameRawg[]>([]);
  const [loading, setLoading] = React.useState(false);

  const theme = useTheme();

  const handleOnChange = async (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (event.target.value.length <= 3) {
      return;
    }
    setLoading(true);
    const uri = encodeURI(
      `https://api.rawg.io/api/games?key=121ec91d1c514fb9918a6a1bdf91a679&search=${event.target.value}`
    );

    const response = await fetch(uri, {
      method: 'GET',
    });

    const gameList = await response.json();
    setOptions(gameList.results as GameRawg[]);
    setLoading(false);
  };

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      id='search-box'
      open={open}
      onOpen={() => {
        setOpen(true);
        handleOpenClose({ type: 'open' });
      }}
      onClose={() => {
        setOpen(false);
        handleOpenClose({ type: 'close' });
      }}
      onChange={onSelected}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label='Search Games'
          variant='filled'
          style={{ backgroundColor: 'white' }}
          onChange={handleOnChange}
          onSelect={onSelected}
          InputProps={{
            ...params.InputProps,
            style: { color: theme.palette.primary.main },
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color='inherit' size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
