import { Card, CardContent, Typography } from '@material-ui/core';
import * as React from 'react';

interface Props {
  description?: string;
}

export default function About({ description }: Props): JSX.Element {
  return (
    <>
      <Card style={{ height: '100%' }}>
        <CardContent>
          <Typography variant='h5'>About</Typography>
          <Typography variant='body1'>
            <div
              dangerouslySetInnerHTML={
                description ? { __html: description.replaceAll('<br />', '') } : { __html: '' }
              }
            />
          </Typography>
        </CardContent>
      </Card>
    </>
  );
}
