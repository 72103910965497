import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

export const themeOptions: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#333333',
      light: '#e6e6e6',
    },
    secondary: {
      main: '#9bf00a',
    },
    background: {
      default: '#FFFFFF',
      paper: '#117D10',
    },
    text: {
      primary: '#ffffff',
      secondary: '#000000',
    },
  },
  typography: {
    fontFamily: 'Work Sans, Robot, Arial',
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    fontSize: 14,
    h5: {
      lineHeight: 1.1,
      marginBottom: '10px',
    },
  },
};
