import { CircularProgress, Grid } from '@material-ui/core';
import * as React from 'react';

export default function LoadingCircle(): JSX.Element {
  return (
    <Grid
      container
      direction='row'
      justify='space-evenly'
      alignItems='baseline'
      style={{ paddingTop: '100px' }}
    >
      <Grid item>
        <CircularProgress className='loading' size={200} />
      </Grid>
    </Grid>
  );
}
