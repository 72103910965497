import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyD6dmpGEA8WghOnst3EHu0STEChAmDOAGM',
  authDomain: 'is-it-on-gamepass.firebaseapp.com',
  projectId: 'is-it-on-gamepass',
  storageBucket: 'is-it-on-gamepass.appspot.com',
  messagingSenderId: '295796298240',
  appId: '1:295796298240:web:b79d2d9aaab1114d20fee5',
  measurementId: 'G-CHHS2MLBT2',
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebaseApp.firestore();

let analytics: firebase.analytics.Analytics | null = null;
firebase.analytics.isSupported().then((isSupported) => {
  if (isSupported) {
    analytics = firebase.analytics();
  }
});

// eslint-disable-next-line no-restricted-globals
if (location.hostname === 'localhost') {
  firestore.useEmulator('localhost', 8080);
}

export default firestore;
export { firebaseApp, analytics };
