import * as React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  slug?: string;
  name?: string;
  screenshot?: string;
}

export default function Metadata({ slug, name, screenshot }: Props): JSX.Element {
  const baseTitle =
    "What's on Game Pass - Your guide to what's new, what's coming soon and what to play on Game Pass.";
  const title = name ? `${name}: ${baseTitle}` : baseTitle;

  const baseDescription =
    "What's on Game Pass - Your guide to what's new, what's coming soon and what to play on Game Pass.";
  const descripion = name ? `${name}: ${baseDescription}` : baseDescription;

  const baseScreenshot = screenshot ? screenshot : null;

  return (
    <Helmet>
      <meta charSet='utf-8' />
      <title>{title}</title>
      <meta name='description' content={descripion} />
      <link rel='canonical' href={`http://www.whats-on-gamepass.com/${slug}`} />
      {baseScreenshot ? <meta property='og:image' content={baseScreenshot} /> : null}

      <meta property='og:title' content={title} />
      <meta property='og:type' content='website' />
    </Helmet>
  );
}
