import { CircularProgress } from '@material-ui/core';
import * as React from 'react';
import { useGameContext } from '../../context/gameContext';

export default function Screenshot(): JSX.Element {
  const game = useGameContext();

  if (!game || !game.short_screenshots) {
    return <CircularProgress />;
  }

  //TODO: add onclick full screen
  return (
    <>
      {game.short_screenshots.slice(0, 4).map((screenshots, key) => (
        <img
          src={screenshots.image}
          title={game.name}
          alt={game.name}
          key={key}
          style={{ height: '48%', width: '48%', padding: '2px' }}
        />
      ))}
    </>
  );
}
