import { AppBar, Container, Grid, Typography } from '@material-ui/core';
import MuiToolbar from '@material-ui/core/Toolbar';
import * as React from 'react';
import { Link } from 'react-router-dom';
import Search from './search';

interface Props {
  onSelected: any;
  handleOpenClose: Function;
}

export default function Toolbar({ onSelected, handleOpenClose }: Props): JSX.Element {
  return (
    <>
      <AppBar>
        <MuiToolbar>
          <Container maxWidth='md'>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              wrap='nowrap'
              spacing={1}
            >
              <Grid item>
                <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                  <Typography align='center' variant='h6' noWrap>
                    What's on Game Pass?
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={8}>
                <Search onSelected={onSelected} handleOpenClose={handleOpenClose} />
              </Grid>
            </Grid>
          </Container>
        </MuiToolbar>
      </AppBar>
    </>
  );
}
