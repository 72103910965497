import { Card, CardContent } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import * as React from 'react';
import Screenshot from './screenshot';
import Title from './title';
import Gamepass from './gamepass';

interface Props {
  isOnGamePass?: boolean;
}

export default function Header({ isOnGamePass }: Props): JSX.Element {
  let style = {};
  if (!isOnGamePass) style = { backgroundColor: '#333333' };

  return (
    <Card style={style}>
      <CardContent>
        <Grid item container xs={12}>
          <Grid item xs={8} md={4}>
            <Title />
          </Grid>
          <Grid item xs={4} md={2}>
            <Gamepass isOnGamePass={isOnGamePass ? true : false} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Screenshot />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
