import {
  Box,
  Container,
  createMuiTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
} from '@material-ui/core';
import 'fontsource-roboto';
import React from 'react';
import { withRouter } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';
import { fetchHotGamePassGames, fetchUpcomingGames } from './api/api';
import { analytics } from './api/firestore';
import './App.css';
import Content from './components/content';
import HotGames from './components/hotgames';
import Metadata from './components/metadata';
import Toolbar from './components/toolbar';
import { Game } from './entities/Game';
import { GameRawg } from './entities/GameRawg';
import { themeOptions } from './themeOptions';

function cleanUpGameList(gameList: Game[]): Game[] {
  return gameList.filter(
    (game) =>
      game.rawg &&
      game.rawg.short_screenshots !== undefined &&
      Array.isArray(game.rawg.short_screenshots) &&
      game.rawg.short_screenshots[0]
  );
}

function App() {
  const [hotGames, setHotGames] = React.useState<Game[]>([]);
  const [upcomingGames, setUpcomingGames] = React.useState<Game[]>([]);
  const [blur, setBlur] = React.useState(false);

  const history = useHistory();

  React.useEffect(() => {
    const trackPageView = () => {
      if (analytics) analytics.logEvent('page_view', { page_path: window.location.pathname });
    };
    trackPageView();
    history.listen(trackPageView);
  }, [history]);

  React.useEffect(() => {
    const fetchData = async () => {
      fetchHotGamePassGames(16).then((gamelist) => setHotGames(cleanUpGameList(gamelist)));
      fetchUpcomingGames(4).then((gamelist) => setUpcomingGames(cleanUpGameList(gamelist)));
    };

    fetchData();
  }, []);

  const handleUserSelection = React.useCallback(
    async (event: React.ChangeEvent<{}>, value: GameRawg | null, reason: string) => {
      if (value && reason === 'select-option' && value.id) {
        if (analytics) {
          analytics.logEvent('search', { search_term: value.slug });

          const item_game: firebase.default.analytics.Item = {
            item_id: value.toString(),
            item_name: value.name,
          };

          analytics.logEvent('select_content', { content_type: 'search-box', items: [item_game] });
        }
        history.push(`/games/${value.slug}`);
      }
    },
    [history]
  );

  const handleSearchOpen = (event: object) => {
    const ev = event as any;
    setBlur(ev.type === 'open');
  };

  const theme = createMuiTheme(themeOptions);

  return (
    <>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <Toolbar onSelected={handleUserSelection} handleOpenClose={handleSearchOpen} />
        <Container maxWidth='md' style={{ filter: blur ? 'blur(5px)' : 'none' }}>
          <Box style={{ paddingTop: '30px' }}>
            <Switch>
              <Route path='/games/:slug'>
                <Content />
              </Route>
              <Route
                path='/'
                render={(props) => (
                  <>
                    <Typography variant='h4' style={{ paddingTop: '60px', paddingBottom: '15px' }}>
                      Welcome to What's on Game Pass
                    </Typography>
                    <Typography>
                      Your guide to what's new, what's coming soon and what to play on Game Pass.
                    </Typography>
                    <HotGames
                      title='Coming Soon'
                      hotgameList={upcomingGames.filter(
                        (game) =>
                          game.rawg &&
                          game.rawg.short_screenshots !== undefined &&
                          Array.isArray(game.rawg.short_screenshots) &&
                          game.rawg.short_screenshots[0]
                      )}
                    />
                    <HotGames
                      title='Recently Added on Game Pass'
                      hotgameList={hotGames.filter(
                        (game) =>
                          game.rawg &&
                          game.rawg.short_screenshots !== undefined &&
                          Array.isArray(game.rawg.short_screenshots) &&
                          game.rawg.short_screenshots[0]
                      )}
                    />
                  </>
                )}
              />
            </Switch>
          </Box>
        </Container>
        <Metadata />
      </ThemeProvider>
    </>
  );
}

export default withRouter(App);
