import { Game } from '../entities/Game';
import { GameRawg } from '../entities/GameRawg';
import firestore from './firestore';

class GameList {
  constructor(readonly list: GameShortcut[]) {}
}

class GameShortcut {
  constructor(readonly id: number, readonly name: string, readonly slug: string) {}
}

async function fetchGamesInGamePass(): Promise<GameShortcut[]> {
  try {
    const doc = await firestore.doc('gamepass/all').get();
    const gameList = doc.data() as GameList;

    return gameList.list;
  } catch (err) {
    return [];
  }
}

async function fetchGameById(id: number): Promise<GameRawg | null> {
  try {
    const snapshot = await firestore.collection('games').where('id', '==', id).limit(1).get();
    return snapshot.docs[0].data().rawg as GameRawg;
  } catch (error) {
    return null;
  }
}

async function fetchGameBySlug(slug: string): Promise<GameRawg | null> {
  try {
    const snapshot = await firestore
      .collection('games')
      .where('rawg.slug', '==', slug)
      .where('status', '==', 'Active')
      .limit(1)
      .get();
    if (snapshot.docs.length === 0) {
      return null;
    }
    return snapshot.docs[0].data().rawg as GameRawg;
  } catch (error) {
    return null;
  }
}

async function fetchHotGamePassGames(limit: number): Promise<Game[] | []> {
  try {
    const snapshot = await firestore
      .collection('games')
      .where('status', '==', 'Active')
      .orderBy('added', 'desc')
      .limit(limit)
      .get();
    return snapshot.docs.map((doc) => doc.data() as Game).filter((game) => game.rawg);
  } catch (error) {
    return [];
  }
}

async function fetchUpcomingGames(limit: number): Promise<Game[] | []> {
  try {
    const snapshot = await firestore
      .collection('games')
      .where('status', '==', 'Coming Soon')
      .orderBy('added', 'desc')
      .limit(limit)
      .get();
    return snapshot.docs.map((doc) => doc.data() as Game).filter((game) => game.rawg);
  } catch (error) {
    return [];
  }
}

export {
  fetchGamesInGamePass,
  fetchHotGamePassGames,
  fetchUpcomingGames,
  fetchGameById,
  fetchGameBySlug,
  GameShortcut,
  GameList,
};
