import { Grid, Typography } from '@material-ui/core';
import { Game } from '../entities/Game';
import Gamecard from './gamecard';

export default function HotGames({
  title,
  hotgameList,
}: {
  title: string;
  hotgameList: Game[];
}): JSX.Element {
  return (
    <>
      <Typography variant='h4' style={{ paddingTop: '20px', paddingBottom: '15px' }}>
        {title}
      </Typography>
      <Grid
        item
        container
        spacing={2}
        direction='row'
        justify='flex-start'
        alignItems='stretch'
        alignContent='stretch'
      >
        {hotgameList.slice(0, 8).map((game) => (
          <Grid key={game.rawg.slug} item xs={6} md={3}>
            <Gamecard key={game.rawg.slug} game={game} />
          </Grid>
        ))}
      </Grid>
    </>
  );
}
