import { Card, CardContent, CircularProgress, Grid } from '@material-ui/core';
import * as React from 'react';
import { Description } from '../../entities/description';
import TableCell from './tableCell';

interface Props {
  data: Description | null;
}
export default function Table({ data }: Props): JSX.Element {
  if (!data) return <CircularProgress />;

  let metacritic;
  if (data.metacritic) {
    metacritic = <TableCell header='Metacritics' text={data.metacritic.toString()} />;
  }

  let developers;
  if (data.developers) {
    developers = (
      <TableCell header='Developers' text={data.developers.map((x) => x.name).join(', ')} />
    );
  }

  let genres;
  if (data.genres) {
    genres = <TableCell header='Genres' text={data.genres.map((x) => x.name).join(', ')} />;
  }

  let platforms;
  if (data.platforms) {
    platforms = (
      <TableCell header='Platform' text={data.platforms.map((x) => x.platform.name).join(', ')} />
    );
  }

  let publishers;
  if (data.publishers) {
    publishers = (
      <TableCell header='Publishers' text={data.publishers.map((x) => x.name).join(', ')} />
    );
  }

  let released;
  if (data.released) {
    released = <TableCell header='Release Date' text={data.released.toString()} />;
  }

  let tags;
  if (data.tags) {
    tags = <TableCell header='tags' text={data.tags.map((x) => x.name).join(', ')} />;
  }

  let website;
  if (data.website) {
    website = (
      <TableCell header='Website' text={data.website.toString()} link={data.website.toString()} />
    );
  }

  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={6}>
            {platforms ? platforms : null}
          </Grid>
          <Grid item xs={6}>
            {metacritic ? metacritic : null}
          </Grid>
          <Grid item xs={6}>
            {genres ? genres : null}
          </Grid>
          <Grid item xs={6}>
            {released ? released : null}
          </Grid>
          <Grid item xs={6}>
            {developers ? developers : null}
          </Grid>
          <Grid item xs={6}>
            {publishers ? publishers : null}
          </Grid>
          <Grid item xs={12}>
            {tags ? tags : null}
          </Grid>
          <Grid item xs={12}>
            {website ? website : null}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
