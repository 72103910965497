import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@material-ui/core';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { analytics } from '../api/firestore';
import { Game } from '../entities/Game';

export default function Gamecard({ game }: { game: Game }): JSX.Element {
  const handleUserClick = () => {
    const item_game: firebase.default.analytics.Item = {
      item_id: game.rawg.id.toString(),
      item_name: game.rawg.name,
    };

    if (analytics)
      analytics.logEvent('select_content', { content_type: 'game-card', items: [item_game] });
  };

  return (
    <Card style={{ height: '100%' }} elevation={5} data-testid='gamecard'>
      <Link
        to={`/games/${game.rawg.slug}`}
        style={{ textDecoration: 'none', color: 'inherit' }}
        onClick={handleUserClick}
      >
        <CardActionArea>
          <CardMedia
            component='img'
            alt={game.game}
            image={game.rawg.short_screenshots[0].image}
            title={game.game}
          />
          <CardContent>
            <Typography variant='h5'>{game.game}</Typography>
            <Typography variant='body2'>
              <b>Release Date:</b> {game.rawg.released}
            </Typography>
            <Typography variant='body2'>
              <b>Genre:</b>{' '}
              {game.rawg.genres ? game.rawg.genres.map((x) => x.name).join(', ') : null}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Link>
    </Card>
  );
}
