import Grid from '@material-ui/core/Grid';
import fetch from 'cross-fetch';
import * as React from 'react';
import { useParams } from 'react-router';
import { fetchGameBySlug } from '../api/api';
import { analytics } from '../api/firestore';
import GameContext from '../context/gameContext';
import { Description } from '../entities/description';
import { GameRawg, ScreenShot } from '../entities/GameRawg';
import LoadingCircle from './loadingCircle';
import Metadata from './metadata';
import About from './tiles/about';
import Header from './tiles/header';
import Table from './tiles/table';

export default function Content(): JSX.Element {
  const { slug } = useParams<{ slug?: string }>();
  const [game, setGame] = React.useState<GameRawg | null>(null);
  const [gamepass, setGamepass] = React.useState<boolean>(false);
  const [gameDescription, setGameDescrition] = React.useState<Description | null>(null);

  React.useEffect(() => {
    const fetchGameData = async () => {
      if (slug) {
        const data = await fetchGameBySlug(slug);
        setGamepass(data !== null);
        const response = await fetch(
          `https://api.rawg.io/api/games/${slug}?key=121ec91d1c514fb9918a6a1bdf91a679`
        );
        const json = (await response.json()) as GameRawg;

        // Add screenshots as they are not in the games api.
        const resp = await fetch(
          `https://api.rawg.io/api/games/${slug}/screenshots?key=121ec91d1c514fb9918a6a1bdf91a679`
        );
        const img = await resp.json();
        json.short_screenshots = img.results as ScreenShot[];

        setGame(json);
      }
    };

    fetchGameData();
  }, [slug]);

  React.useEffect(() => {
    const fetchDescription = async (id: number | undefined) => {
      if (id === undefined) {
        return;
      }
      const resp = await fetch(
        `https://api.rawg.io/api/games/${id}?key=121ec91d1c514fb9918a6a1bdf91a679`
      );
      const desc = (await resp.json()) as Description;
      setGameDescrition(desc);
    };

    if (game) {
      fetchDescription(game.id);
    }
  }, [game]);

  React.useEffect(() => {
    if (game) {
      const item_game: firebase.default.analytics.Item = {
        item_id: game.id.toString(),
        item_name: game.name,
      };
      if (analytics) analytics.logEvent('view_item', { items: [item_game] });
    }
  }, [game]);

  if (!game) {
    return <LoadingCircle />;
  }

  return (
    <GameContext.Provider value={game}>
      <Grid
        container
        spacing={2}
        direction='row'
        justify='space-evenly'
        alignItems='stretch'
        style={{ paddingTop: '60px' }}
      >
        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <Header isOnGamePass={gamepass} />
        </Grid>

        <Grid item xs={12} style={{ textAlign: 'left' }}>
          <About description={gameDescription?.description} />
        </Grid>
        <Grid item container xs={12}>
          <Table data={gameDescription} />
        </Grid>
      </Grid>
      <Metadata
        slug={game.slug}
        name={game.name}
        screenshot={
          game.short_screenshots && game.short_screenshots.length > 0
            ? game.short_screenshots[0].image
            : undefined
        }
      />
    </GameContext.Provider>
  );
}
