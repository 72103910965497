import { Grid, Link, Typography } from '@material-ui/core';
import * as React from 'react';

interface Prop {
  header: string;
  text: string;
  link?: string;
}

export default function TableCell({ header, text, link }: Prop): JSX.Element {
  return (
    <Grid container direction='column' justify='flex-start' alignItems='flex-start'>
      <Grid item>
        <Typography variant='overline' style={{ fontWeight: 'bold' }}>
          {header}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant='caption'>
          {link ? (
            <Link href={link} color='inherit'>
              {text}
            </Link>
          ) : (
            text
          )}
        </Typography>
      </Grid>
    </Grid>
  );
}
